import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/navbar/navbar.component').then(
        (m) => m.NavbarComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },
      // {
      //   path: 'solution',
      //   loadComponent: () =>
      //     import(
      //       './pages/management-solution/management-solution.component'
      //     ).then((m) => m.ManagementSolutionComponent),
      // },
      // {
      //   path: 'contact-us',
      //   loadComponent: () =>
      //     import('./pages/contact-us/contact-us.component').then(
      //       (m) => m.ContactUsComponent
      //     ),
      // },
      // {
      //   path: 'experience',
      //   loadComponent: () =>
      //     import('./pages/experience/experience.component').then(
      //       (m) => m.ExperienceComponent
      //     ),
      // },
      {
        path: '**',
        redirectTo: '/',
      },
    ],
  },
];
